import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Logo from "../assets/img/logo.png"
import { Link, animateScroll as scroll } from "react-scroll"

const HeaderContainer = () => {
  const [isTop, setIsTop] = useState(true)

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      return
    }

    document.addEventListener("scroll", () => {
      const top = window.scrollY < 100
      if (top !== isTop) {
        // debugger
        setIsTop(top)
      }
    })
  }, [])

  return (
    <header className="header">
      <nav
        className={`navbar navbar-expand-lg fixed-top bg-transparent navbar-dark ${
          isTop ? "" : "affix"
        }`}
      >
        <div className="container">
          <a className="navbar-brand" href="index.html">
            <img src={Logo} alt="logo" className="img-fluid" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse h-auto"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto menu">
              <li>
                <Link
                  to="home"
                  href="#home"
                  spy={true}
                  smooth={true}
                  className="page-scroll"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="features"
                  href="#features"
                  spy={true}
                  smooth={true}
                  className="page-scroll"
                >
                  Features
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  href="#about"
                  spy={true}
                  smooth={true}
                  className="page-scroll"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="help"
                  href="#help"
                  spy={true}
                  smooth={true}
                  className="page-scroll"
                >
                  Help
                </Link>
              </li>
              {/* <li>
                <Link
                  to="pricing"
                  href="#pricing"
                  spy={true}
                  smooth={true}
                  className="page-scroll"
                >
                  Pricing
                </Link>
              </li> */}
              <li>
                <Link
                  to="download"
                  href="#download"
                  spy={true}
                  smooth={true}
                  className="page-scroll"
                >
                  Download
                </Link>
              </li>
              <li>
                <Link
                  to="contact"
                  href="#contact"
                  spy={true}
                  smooth={true}
                  className="page-scroll"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

class Header extends React.Component {
  render() {
    return <HeaderContainer></HeaderContainer>
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
