import React, { Component } from "react"
import SliderBg from "../assets/img/slider-bg-1.jpg"
import WaveShape from "../assets/img/wave-shap.svg"
import Hero5 from "../assets/img/hero5-app2.png"
import Mobile2 from "../assets/img/app-mobile-image-2.png"
import AutomateYourOperation from "../assets/img/automate-your-operation2.png"
import MobilePana from "../assets/img/mobile-pana.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import intowVideo from "../assets/video/intowVideo.mp4"
import MobileSetup from "../assets/img/InTow-Connect-Mobile-Setup.pdf"
import UserGuide from "../assets/img/InTow-Connect-Mobile-User-Guide.pdf"
import ConsoleUserGuide from "../assets/img/InTow-Connect-Console-Setup-and-User-Guide.pdf"
import Header from "../components/header"
import Footer from "../components/footer"

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <Header />
        <SEO title="Welcome to InTow Connect" />
        <section
          id="home"
          className="ptb-100 bg-image overflow-hidden"
          image-overlay="8"
        >
          <div
            className="background-image-wraper"
            style={{ backgroundImage: `url(${SliderBg})` }}
          ></div>
          <div
            className="hero-bottom-shape-two"
            style={{
              backgroundImage: `url(${WaveShape})`,
              backgroundPosition: "bottom center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="effect-1 opacity-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 361.1 384.8"
              className="injected-svg svg_img dark-color"
            >
              <path d="M53.1,266.7C19.3,178-41,79.1,41.6,50.1S287.7-59.6,293.8,77.5c6.1,137.1,137.8,238,15.6,288.9 S86.8,355.4,53.1,266.7z"></path>
            </svg>
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
              <div className="col-md-12 col-lg-6">
                <div className="hero-slider-content text-white py-5 text-left">
                  <h2 className="text-white">Go Digital with</h2>
                  <h1 className="text-white">InTow Connect Mobile!</h1>
                  <p className="lead">
                    Today’s leading motor clubs are in search of service
                    providers who have invested in digital technology. It allows
                    both motor clubs and service providers to be more efficient
                    in the handling and distribution of calls, and more
                    importantly, provides higher service levels to your mutual
                    customer, the motor club member.
                  </p>

                  <div className="action-btns mt-4">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a
                          href="https://apps.apple.com/ca/app/intow-connect/id1508358569"
                          target="_blank"
                          className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                        >
                          <span className="fab fa-apple icon-size-sm mr-3"></span>
                          <div className="download-text text-left">
                            <small>Download form</small>
                            <h5 className="mb-0">App Store</h5>
                          </div>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.intowconnect"
                          target="_blank"
                          className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                        >
                          <span className="fab fa-google-play icon-size-sm mr-3"></span>
                          <div className="download-text text-left">
                            <small>Download form</small>
                            <h5 className="mb-0">Google Play</h5>
                          </div>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://www.intowconnect.com/console/setup.exe"
                          target="_blank"
                          className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                        >
                          <span className="fab fa-windows icon-size-sm mr-3"></span>
                          <div className="download-text text-left">
                            <small>Download for</small>
                            <h5 className="mb-0">Windows</h5>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="img-wrap">
                  <img src={Hero5} alt="app image" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="features"
          className="promo-section ptb-100 position-relative overflow-hidden"
        >
          <div className="effect-2 opacity-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 463.6 616"
              className="injected-svg svg_img dark-color"
            >
              <path d="M148.4,608.3C25.7,572.5-3.5,442.2,0.3,375.8s24.8-117,124.8-166.5s125.7-77.4,165-129.6 c43.2-57.4,96.5-94.4,127.9-73c63,43,53.9,280,14,358s-68.9,75.5-98.9,118.7S271,644,148.4,608.3z"></path>
            </svg>
          </div>
          <div className="container">
            <div className="row">
              <a href="https://www.intowmanager.com" style={{width: "200vw", margin: '-80px auto 50px'}} target="_blank">
                  <img src={AutomateYourOperation} alt="about us" className="img-fluid"/>
              </a>
            </div>
            <div className="row">
              <div className="col-lg-7 col-md-10">
                <div className="section-heading text-left">
                  <h2>Join the Revolution</h2>
                  <p>
                    You can now join the digital revolution at a fraction of the
                    cost of traditional towing management systems with InTow
                    Connect Mobile from DENTED Development Inc. You can
                    affordably enter the same digital space that your largest
                    competitors have taken advantage of for years. For as little
                    as $20.00US, you can start accepting service call requests
                    from all major clubs.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4 text-left">
                  <div className="card-body" style={{ height: 400 }}>
                    <div className="pb-2">
                      <span className="fas fa-concierge-bell icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Cost</h5>
                      <p className="mb-0">
                        Like pre-paid phones where you buy a block of minutes,
                        with InTow Connect Mobile you pre-pay for a block of
                        calls (or transactions). When you accept a call from a
                        motor club or private account, your call block is
                        reduced by 1 transaction. When your call block runs low,
                        we send an invoice to replenish your block. And your
                        call block will never expire. The minimum call block
                        size is 100 transactions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4 text-left">
                  <div className="card-body" style={{ height: 400 }}>
                    <div className="pb-2">
                      <span className="fas fa-window-restore icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Support</h5>
                      <p className="mb-0">
                        Support is included in your call block purchase. No
                        other fees are required.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4 text-left">
                  <div className="card-body" style={{ height: 400 }}>
                    <div className="pb-2">
                      <span className="fas fa-sync-alt icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Scalable</h5>
                      <p className="mb-0">
                        Available on both Apple and Android phones, tablets and
                        notepads. No other hardware required.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="about"
          className="about-us ptb-100 position-relative gray-light-bg"
        >
          <div className="container">
            <div className="row align-items-center justify-content-lg-between justify-content-md-center">
              <div className="col-md-5 col-lg-4">
                <div className="about-content-right">
                  <img src={Mobile2} alt="about us" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-12 col-lg-7">
                <div className="about-content-left section-heading pt-20 text-left">
                  <h2>How it works</h2>

                  <ul className="check-list-wrap pt-3 text-left">
                    <li>Download the app</li>
                    <li>Make an in-app purchase of your call block</li>
                    <li>Register with your motor clubs</li>
                    <li>Create users and assign logins to staff</li>
                  </ul>
                  <div className="action-btns mt-4">
                    <a
                      href="#"
                      className="btn btn-brand-02 mr-3 btn-md btn-rounded"
                    >
                      Get Started Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* start help 2 */}

        <section
          id="help"
          className="work-process-section position-relative  ptb-100 overflow-hidden"
        >
          <div className="container">
            <div className="row">
              <a href="https://www.intowmanager.com" style={{width: "200vw", margin: '-80px auto 50px'}} target="_blank">
                  <img src={AutomateYourOperation} alt="about us" className="img-fluid"/>
              </a>
            </div>
            <div className="effect-2 opacity-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 463.6 616"
                className="injected-svg svg_img dark-color"
              >
                <path d="M148.4,608.3C25.7,572.5-3.5,442.2,0.3,375.8s24.8-117,124.8-166.5s125.7-77.4,165-129.6 c43.2-57.4,96.5-94.4,127.9-73c63,43,53.9,280,14,358s-68.9,75.5-98.9,118.7S271,644,148.4,608.3z"></path>
              </svg>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2>Help Process</h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-md-center justify-content-sm-center">
              <div className="col-md-12 col-lg-6">
                <div className="work-process-wrap">
                  <div className="process-single-item">
                    <div className="process-icon-item left-shape">
                      <div className="d-flex align-items-center">
                        <div className="process-icon mr-4">
                          <i className="fas fa-download color-primary"></i>
                        </div>
                        <div className="process-content text-left">
                          <h5>STEP 1</h5>
                          <p>
                            Once the app is downloaded and installed, REGISTER
                            your company in InTow Connect Mobile.
                          </p>
                        </div>
                      </div>
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="process-single-item">
                    <div className="process-icon-item right-shape">
                      <div className="d-flex align-items-center">
                        <div className="process-icon ml-4">
                          <i className="fas fa-users color-primary"></i>
                        </div>
                        <div className="process-content text-right">
                          <h5>STEP 2</h5>
                          <p>
                            Setup your Administrator account. This Administrator
                            account will be responsible for setting up all users
                            of InTow Connect Mobile. You can receive Push
                            Notices, Text Messages or both to be notified when
                            new calls arrive.{" "}
                          </p>
                        </div>
                      </div>
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="process-single-item">
                    <div className="process-icon-item left-shape mb-0">
                      <div className="d-flex align-items-center">
                        <div className="process-icon mr-4">
                          <i className="fas fa-truck color-primary"></i>
                        </div>
                        <div className="process-content text-left">
                          <h5>STEP 3</h5>
                          <p>
                            Setup DRIVER and DISPATCHER accounts in InTow
                            Connect Mobile. Drivers will only see and will only
                            receive PUSH NOTICES for CALLS assigned to them.
                            Administrator and Dispatchers will see ALL CALLS.
                            You can receive Push Notices, Text Messages or both
                            to be notified when new calls arrive.{" "}
                          </p>
                        </div>
                      </div>
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <div className="process-single-item">
                    <div className="process-icon-item right-shape">
                      <div className="d-flex align-items-center">
                        <div className="process-icon ml-4">
                          <i className="fas fa-key color-primary"></i>
                        </div>
                        <div className="process-content text-right pt-100">
                          <h5>STEP 4</h5>
                          <p>
                            For motor clubs you currently work for, ask them to
                            send your digital Provider ID. For motor clubs you
                            want to work for, make them aware you now support
                            their digital platform. Motor clubs are anxious to
                            have you on their digital platform and will provide
                            all necessary information to get you registered with
                            InTow Connect.
                          </p>
                        </div>
                      </div>
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <div className="process-single-item">
                    <div className="process-icon-item left-shape mb-0">
                      <div className="d-flex align-items-center">
                        <div className="process-icon mr-4">
                          <i className="fas fa-envelope color-primary"></i>
                        </div>
                        <div className="process-content text-left">
                          <h5>STEP 5</h5>
                          <p>
                            For Private Accounts, use Internet Dispatching as
                            your Provider and your customers email address. Your
                            customer will receive a email welcoming them to
                            InTow Connect and can now request services via a
                            webpage link in the email message.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="action-btns mt-4 text-center">
                        <a href={MobileSetup} target="_blank" className="btn btn-brand-02 mr-3 btn-md btn-rounded" >
                           Download Setup Guide
                         </a>
                        <a href={UserGuide} target="_blank"  className="btn btn-brand-02 mr-3 btn-md btn-rounded" >
                            Download User Guide
                        </a>
                        <a href={UserGuide} target="_blank"  className="btn btn-brand-02 mr-3 btn-md btn-rounded" >
                            Download User Guide
                        </a>
                         </div> */}
              </div>

              <div className="col-md-12 col-lg-6 text-center">
                <div className="img-wrap">
                  <video
                    playsInline
                    autoPlay
                    loop
                    muted
                    width="375"
                    height="812"
                  >
                    <source src={intowVideo} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="row col-md-12 ">
                <div className="section-heading text-center mb-5">
                  <div className="action-btns mt-4 ml-4 text-left">
                    <a
                      href={MobileSetup}
                      target="_blank"
                      className="btn btn-brand-02 mr-3 mt-2 btn-md btn-rounded"
                    >
                      Download Setup Guide
                    </a>
                    <a
                      href={UserGuide}
                      target="_blank"
                      className="btn btn-brand-02 mr-3 mt-2 btn-md btn-rounded"
                    >
                      Download User Guide
                    </a>
                    <a
                      href={ConsoleUserGuide}
                      target="_blank"
                      className="btn btn-brand-02 mr-3 mt-2 btn-md btn-rounded"
                    >
                      Download Console Guide
                    </a>
                  </div>
                </div>
                {/* <div className="row col-md-12  ml-4">
                <div className="section-heading text-center mb-5">
                  <div className="action-btns mt-4 text-left">
                      <h4>InTow Connect for Windows</h4>
                      <p>Would you prefer to run InTow Connect from a Windows PC?  
                         Click here to download and install the InTow Connect Console application. 
                         You can install the Windows console application on as many PC’s as needed. 
                         You get all the same great features as InTow Connect on your mobile device.</p>
                  </div>
                </div>
                </div> */}
                
              </div>
              
            </div>
          </div>
        </section>

        {/* end help2 */}

        <section
          id="pricing"
          className="position-relative feature-section ptb-100 gray-light-bg"
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 col-lg-6">
                <div className="feature-contents section-heading text-left">
                  <h2>Supported Motor Clubs</h2>
                  <ul className="dot-circle pt-2 text-left">
                    <li>
                      <strong>Allstate</strong> (US and Canada),
                    </li>
                    <li>
                      <strong>Tesla</strong> (US and Canada),
                    </li>
                    <li>
                      <strong>Agero</strong> (US Only)
                    </li>
                    <li>
                      <strong>Road America / Road Canada</strong> (US and
                      Canada)
                    </li>
                    <li>
                      <strong>Allied</strong> (US and Canada)
                    </li>
                    <li>
                      <strong>AXA</strong> (US and Canada)
                    </li>
                    <li>
                      <strong>FleetNet</strong> (US Only)
                    </li>
                    <li>
                      <strong>GEICO</strong> (US Only)
                    </li>
                    <li>
                      <strong>Nation Safe Drivers</strong> (US Only)
                    </li>
                    <li>
                      <strong>Quest</strong> (US Only)
                    </li>
                    <li>
                      <strong>Sykes</strong> (Canada Only)
                    </li>
                    <li>
                      <strong>USAC</strong> (US Only)
                    </li>
                    <li>
                      <strong>InTow Internet Dispatching</strong> for private
                      accounts you want to setup with Digital Dispatching.
                    </li>
                  </ul>
                  {/* <h2>Call Block Cost Breakdown</h2>
              <ul className="dot-circle pt-2">
                <li>100 calls - $20.00US (.20 cents per call)</li>
                <li>100 calls - $20.00US (.20 cents per call)</li>
                <li>500 calls - $90.00US (.18 cents per call) </li>
                <li>700 calls - $119.00US (.17 cents per call)</li>
                <li>800 calls - $128.00US (.16 cents per call)</li>
                <li>1000 calls - $150.00US (.15 cents per call)</li>
              </ul> */}
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="download-img">
                  <img src={MobilePana} alt="download" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
          <div className="row">
              <a href="https://www.intowmanager.com" style={{width: "200vw", margin: '100px auto 0px'}} target="_blank">
                  <img src={AutomateYourOperation} alt="about us" className="img-fluid"/>
              </a>
            </div>
          </div>
        </section>

        <section
          id="download"
          className="position-relative overflow-hidden ptb-100"
        >
          <div className="mask-65"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center text-white">
                  <h2 className="text-white">The Most Used App Platforms</h2>
                  {/* <p>
                Start working with that can provide everything you need to
                generate awareness, drive traffic, connect. Efficiently
                transform granular value with client-focused content.
              </p> */}
                </div>
              </div>
            </div>

            <div className="row justify-content-md-center justify-content-sm-center mt-3">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="bg-white p-5 rounded text-center shadow mt-lg-0 mt-4">
                  <div className="icon-text-wrap">
                    <i className="fab fa-apple icon-size-md color-primary mb-2"></i>
                    <h5>Apple Store</h5>
                  </div>
                  <div className="p-20px">
                    {/* <p className="m-0px">
                  Seamlessly foster fully researched convergence and interactive
                  resources administrate data.
                </p> */}
                    <a
                      className="btn btn-brand-02 btn-sm btn-rounded"
                      href="https://apps.apple.com/ca/app/intow-connect/id1508358569"
                      target="_blank"
                    >
                      Download Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="bg-white p-5 rounded text-center shadow mt-lg-0 mt-4">
                  <div className="icon-text-wrap">
                    <i className="fab fa-google-play icon-size-md color-primary mb-2"></i>
                    <h5>Google Play</h5>
                  </div>
                  <div className="p-20px">
                    {/* <p className="m-0px">
                  Assertively build B2C manufactured products before front-end
                  optimize frictionless communities tailers.
                </p> */}
                    <a
                      className="btn btn-brand-02 btn-sm btn-rounded"
                      href="https://play.google.com/store/apps/details?id=com.intowconnect"
                      target="_blank"
                    >
                      Download Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="bg-white p-5 rounded text-center shadow mt-lg-0 mt-4">
                  <div className="icon-text-wrap">
                    <i className="fab fa-windows icon-size-md color-primary mb-2"></i>
                    <h5>Windows Console</h5>
                  </div>
                  <div className="p-20px">
                    {/* <p className="m-0px">
                  Assertively build B2C manufactured products before front-end
                  optimize frictionless communities tailers.
                </p> */}
                    <a
                      className="btn btn-brand-02 btn-sm btn-rounded"
                      href="https://www.intowconnect.com/console/setup.exe"
                      target="_blank"
                    >
                      Download Now
                    </a>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>
   
        <div className="container">
         <div className="col-lg-12 col-md-6">
                <div className="section-heading text-center pb-80 pt-80">
                  <div className=" text-center">
                      <h4>InTow Connect for Windows</h4>
                      <p>Would you prefer to run InTow Connect from a Windows PC?  
                         Click <a href="https://www.intowconnect.com/console/setup.exe" target="_blank">here</a> to download and install the InTow Connect Console application. 
                         You can install the Windows console application on as many PC’s as needed. 
                         You get all the same great features as InTow Connect on your mobile device.</p>
                  </div>
                </div>
          </div>
        </div>
             <Footer />
      </Layout>
    )
  }
}
export default IndexPage
